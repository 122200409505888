import { AppDispatch } from 'index';

export const setModals = (dispatch: AppDispatch, payload: Object) => {
  dispatch({
    type: 'planDetails/setModals',
    payload,
  });
};

export const setError = (dispatch: AppDispatch, payload: string | null) => {
  dispatch({
    type: 'planDetails/setError',
    payload,
  });
};

export const setLayout = (dispatch: AppDispatch, payload: Object) => {
  dispatch({
    type: 'planDetails/setLayout',
    payload,
  });
};
