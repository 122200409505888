import UploadFileDropZone from 'pages/PlanList/uploadFileDropzone';
import { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getFirebaseBackend } from '../../../../../firebase';
import {
  downloadProvisionsTemplate,
  validateUploadedFile,
} from '../../../../../helpers/export_provisions';
import { ExportTemplate } from '../../../../../helpers/firebase_helper';

interface CreateExportTemplateModalProps {
  isOpen: boolean;
  setIsOpen: (arg0: boolean) => void;
}

const CreateExportTemplateModal = ({ isOpen, setIsOpen }: CreateExportTemplateModalProps) => {
  const [file, setFile] = useState<ExportTemplate>();

  const handleCreateTemplate = async (file: any) => {
    const firebaseHelper = getFirebaseBackend();

    const fileFromDB = (await firebaseHelper.getTemplateFile(file.id)) as ExportTemplate;
    setFile(fileFromDB);
  };

  return (
    <Modal isOpen={isOpen} toggle={() => setIsOpen}>
      <ModalHeader>Create Export Template</ModalHeader>
      <ModalBody>
        {!file ? (
          <div>
            <p>
              All provision placeholders within the sheet will be replaced by the resolved answers
              from the latest revision. The file should be in .xlsx format.
            </p>
            <p>
              <a onClick={async () => downloadProvisionsTemplate()} href='#'>
                Click here to download the standard template.
              </a>
            </p>

            <UploadFileDropZone
              onBeforeUpload={validateUploadedFile}
              onCompleteUpload={handleCreateTemplate}
              uploadType='template'
            />
          </div>
        ) : (
          <div>
            <p>Your new template "{file.templateName}" have been saved.</p>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          className='px-3'
          color='gray-400'
          onClick={() => {
            setFile(undefined);
            setIsOpen(false);
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateExportTemplateModal;
