import { AppDispatch } from 'index';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'reactstrap';
import { setError } from '../dispatcher';

const PlanDetailsError: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { errorMessage } = useSelector((state: any) => ({
    errorMessage: state.PlanDetails.error as string,
  }));

  return (
    <>
      {errorMessage && (
        <div className='align-self-center text-center px-3 animate-all'>
          <Alert
            color='warning'
            role='alert'
            toggle={() => {
              setError(dispatch, '');
            }}
          >
            {errorMessage}
          </Alert>
        </div>
      )}
    </>
  );
};

export default PlanDetailsError;
