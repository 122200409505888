"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryList = exports.ProvisionCategory = exports.PlanStatus = exports.PDAICollections = void 0;
var PDAICollections;
(function (PDAICollections) {
    PDAICollections["Plans"] = "adoptionAgreements";
    PDAICollections["Users"] = "users";
    PDAICollections["UserLogins"] = "userLogins";
    PDAICollections["ScheduledTasks"] = "scheduledTasks";
    PDAICollections["ConfigurationParameters"] = "configurationParameters";
    PDAICollections["Organizations"] = "organizations";
})(PDAICollections = exports.PDAICollections || (exports.PDAICollections = {}));
var PlanStatus;
(function (PlanStatus) {
    PlanStatus["Uploading"] = "uploading";
    PlanStatus["ProcessingText"] = "processingText";
    PlanStatus["ProcessingAI"] = "processingAI";
    PlanStatus["ProcessingLocationOCR"] = "processingLocationOCR";
    PlanStatus["ProcessingLocationAI"] = "processingLocationAI";
    PlanStatus["InUserReview"] = "inUserReview";
    PlanStatus["Reviewed"] = "reviewed";
    PlanStatus["Archived"] = "archived";
    PlanStatus["Failed"] = "failed";
    PlanStatus["Deleted"] = "deleted";
})(PlanStatus = exports.PlanStatus || (exports.PlanStatus = {}));
var ProvisionCategory;
(function (ProvisionCategory) {
    ProvisionCategory["PLAN"] = "Plan";
    ProvisionCategory["EMPLOYER"] = "Sponsor/Employer";
    ProvisionCategory["MIN_AGE_SERVICE_REQS"] = "Minimum Age and Service Requirements";
    ProvisionCategory["COMPENSATION"] = "Compensation";
    ProvisionCategory["CONTRIBUTIONS"] = "Contributions";
    ProvisionCategory["ANNUAL_COMPLIANCE"] = "Annual Compliance";
    ProvisionCategory["RETIREMENT"] = "Retirement";
    ProvisionCategory["VESTING_AND_FORFEITURES"] = "Vesting and Forfeitures";
    ProvisionCategory["DISTRIBUTION"] = "Distribution";
    ProvisionCategory["LEGISLATION"] = "Legislation";
    ProvisionCategory["LOAN"] = "Loan";
})(ProvisionCategory = exports.ProvisionCategory || (exports.ProvisionCategory = {}));
exports.CategoryList = [
    'Plan',
    'Sponsor/Employer',
    'Minimum Age and Service Requirements',
    'Compensation',
    'Contributions',
    'Annual Compliance',
    'Retirement',
    'Vesting and Forfeitures',
    'Distribution',
    'Legislation',
    'Loan',
];
