import ProgressCircle from 'Components/Common/ProgressCircle';
import { Timestamp } from 'firebase/firestore';
import { AppDispatch } from 'index';
import { getReviewPercentage, getStatusDescription } from 'pages/PlanList/planHelper';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row, Spinner } from 'reactstrap';
import { fetchPlanById, updatePlan } from 'slices/thunk';
import { getFirebaseBackend } from '../../../firebase';
import { Plan } from '../../../helpers/firebase_helper';
import ExportPlanButton from '../components/PlanExport/ExportPlanButton';
import { setLayout, setModals } from '../dispatcher';
import PlanSummaryModal from '../modals/planSummaryModal';

const PlanDetailsHeader: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { plan, isSummaryLoading } = useSelector((state: any) => ({
    plan: state.PlanDetails.plan as Plan,
    isSummaryLoading: state.PlanDetails.layout.summaryLoading,
  }));

  const navigate = useNavigate();

  const createPlanSummary = () => {
    setLayout(dispatch, { summaryLoading: true });
    const firebaseHelper = getFirebaseBackend();
    firebaseHelper.createPlanSummary(plan.id!).then(() => {
      setLayout(dispatch, { summaryLoading: false });
      dispatch(fetchPlanById(plan.id!));
    });
  };

  const handleArchivePlan = () => {
    dispatch(updatePlan({ id: plan.id!, updatedData: { status: 'archived' } }));
  };

  const handleInUserReviewPlan = () => {
    dispatch(updatePlan({ id: plan.id!, updatedData: { status: 'inUserReview' } }));
  };

  const handleReviewPlan = () => {
    if (plan?.answers?.some((answer) => !answer.reviewed)) {
      setModals(dispatch, { markPlanAsReviewed: true });
    } else {
      dispatch(updatePlan({ id: plan.id!, updatedData: { status: 'reviewed' } }));
    }
  };

  const handlePlanSummaryButton = () => {
    if (isSummaryLoading) return;

    if (!plan.summary) {
      createPlanSummary();
    } else {
      setModals(dispatch, { summary: true });
    }
  };

  return (
    <>
      <Row className='px-3 d-flex align-items-center'>
        <Col className='col-4'>
          <div className='planDetail-top-section'>
            Document: <strong>{plan.documentName || plan.fileName}</strong>
            <br></br>
            Uploaded at:{' '}
            <strong>
              {(plan.createdAt as Timestamp)?.toDate().toLocaleDateString('en', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })}
            </strong>
            <br></br>
            <div className='d-flex align-items-center gap-2'>
              <span>
                Status: <strong>{getStatusDescription(plan.status)}</strong>
              </span>
              {plan.status === 'inUserReview' && (
                <ProgressCircle percentage={getReviewPercentage(plan)} />
              )}
            </div>
            <div>
              <button className='link-button' onClick={handlePlanSummaryButton}>
                {isSummaryLoading ? (
                  <div className='d-flex gap-2 align-items-center'>
                    Creating Plan Summary
                    <Spinner className='ms-2 small-spinner' />
                  </div>
                ) : plan.summary ? (
                  'See Plan Summary'
                ) : (
                  <div className='d-flex gap-2 align-items-center'>Create Plan Summary</div>
                )}
              </button>
            </div>
          </div>
        </Col>
        <Col className='col-8'>
          <div className='d-flex justify-content-end gap-3'>
            {plan.status !== 'reviewed' &&
              plan.status !== 'processingLocationOCR' &&
              plan.status !== 'processingLocationAI' && (
                <Button
                  type='button'
                  color='secondary'
                  className='btn px-4'
                  onClick={handleReviewPlan}
                >
                  Mark Plan as Reviewed
                </Button>
              )}
            {plan.status !== 'inUserReview' &&
              plan.status !== 'processingLocationOCR' &&
              plan.status !== 'processingLocationAI' && (
                <Button
                  type='button'
                  color='secondary'
                  className='btn px-4'
                  onClick={handleInUserReviewPlan}
                >
                  Mark Plan as Pending Review
                </Button>
              )}
            {plan.status !== 'archived' &&
              plan.status !== 'processingLocationOCR' &&
              plan.status !== 'processingLocationAI' && (
                <Button
                  type='button'
                  color='secondary'
                  className='btn px-4'
                  onClick={handleArchivePlan}
                >
                  Archive Plan
                </Button>
              )}

            <ExportPlanButton plan={plan} />

            <Button
              type='button'
              color='gray-400'
              className='btn px-5 my-0'
              onClick={() => navigate(`/plans`)}
            >
              Back to My Plans
            </Button>
          </div>
        </Col>
      </Row>

      {plan?.summary && <PlanSummaryModal createSummaryFn={createPlanSummary} />}
    </>
  );
};

export default PlanDetailsHeader;
