import { Plan } from '@helpers/firebase_helper';
import { AppDispatch } from 'index';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { setModals } from '../dispatcher';

interface PlanSummaryModalProps {
  createSummaryFn: () => void;
}

const PlanSummaryModal: React.FC<PlanSummaryModalProps> = ({ createSummaryFn }) => {
  const dispatch: AppDispatch = useDispatch();
  const { plan, showSummaryModal } = useSelector((state: any) => ({
    plan: state.PlanDetails.plan as Plan,
    showSummaryModal: state.PlanDetails.modals.summary,
  }));

  const setShowSummaryModal = (show: boolean) => {
    setModals(dispatch, { summary: show });
  };
  return (
    <>
      <Modal
        size='lg'
        isOpen={showSummaryModal}
        toggle={() => setShowSummaryModal(!showSummaryModal)}
        className='modal-answers'
      >
        <ModalHeader toggle={() => setShowSummaryModal(!showSummaryModal)}></ModalHeader>
        <ModalBody>
          <div
            className='px-3 summary-gemini'
            dangerouslySetInnerHTML={{
              __html: plan.summary!.replace('```html', '').replace('```', ''),
            }}
          />
        </ModalBody>
        <ModalFooter className='px-4'>
          <Button
            className='px-3'
            title='Use this option if you edited a provision and want the Plan Summary to reflect the new provision value.'
            color='gray-400'
            onClick={() => {
              createSummaryFn();
              setShowSummaryModal(!showSummaryModal);
            }}
          >
            Recreate Plan Summary
          </Button>
          <Button
            className='px-3'
            color='gray-400'
            onClick={() => setShowSummaryModal(!showSummaryModal)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PlanSummaryModal;
