import { ExportTemplate, Plan } from '@helpers/firebase_helper';
import { getDownloadURL, ref } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonDropdown,
  ButtonGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { createSelector } from 'reselect';
import { fetchUserTemplates } from 'slices/template/thunk';
import { getFirebaseBackend } from '../../../../firebase';
import { downloadProvisionsExport } from '../../../../helpers/export_provisions';
import CreateExportTemplateModal from './modals/CreateExportTemplateModal';
import EditTemplatesModal from './modals/EditTemplatesModal';

interface ExportPlanButtonProps {
  plan: Plan;
}

const ExportPlanButton = ({ plan }: ExportPlanButtonProps) => {
  const dispatch = useDispatch<any>();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCreateTemplateOpen, setIsCreateTemplateOpen] = useState(false);
  const [isEditTemplatesOpen, setIsEditTemplatesOpen] = useState(false);
  const [refreshTemplates, setRefreshTemplates] = useState(true);

  const handleModalState = (state: boolean) => {
    if (!state) {
      setRefreshTemplates(true);
    }
    setIsCreateTemplateOpen(state);
  };

  const selectorTemplates = createSelector(
    (state: any) => state.Templates,
    (Templates) => ({
      templates: Templates.templates,
      loading: Templates.loading,
      error: Templates.error,
    }),
  );

  const { templates, loading } = useSelector(selectorTemplates);

  const firebaseHelper = getFirebaseBackend();

  useEffect(() => {
    if (refreshTemplates) {
      dispatch(fetchUserTemplates());
      setRefreshTemplates(false);
    }
  }, [refreshTemplates]);

  return (
    <>
      <ButtonGroup>
        <ButtonDropdown isOpen={isDropdownOpen} toggle={() => setIsDropdownOpen(!isDropdownOpen)}>
          <Button
            // TODO: remove this and set default to last used template
            onClick={() => downloadProvisionsExport(plan)}
          >
            Export Plan
          </Button>
          <DropdownToggle caret color='primary' className='dropdown-toggle-split'>
            <i className='mdi mdi-chevron-down' />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Initial Templates</DropdownItem>
            <DropdownItem onClick={() => downloadProvisionsExport(plan)}>
              PlanPort Default
            </DropdownItem>

            <DropdownItem divider />
            <DropdownItem header>User Templates</DropdownItem>
            {loading ? (
              <DropdownItem disabled>(loading)</DropdownItem>
            ) : (
              <>
                {templates.length > 0 ? (
                  templates.map((template: ExportTemplate) => (
                    <DropdownItem
                      key={template.id}
                      onClick={async () => {
                        const url = await getDownloadURL(
                          ref(firebaseHelper.storage, template.fileUri),
                        );
                        await downloadProvisionsExport(plan, url);
                      }}
                    >
                      {template.templateName}
                    </DropdownItem>
                  ))
                ) : (
                  <DropdownItem disabled>No templates found</DropdownItem>
                )}
              </>
            )}
            <DropdownItem divider />
            <DropdownItem onClick={() => handleModalState(true)}>Create new template</DropdownItem>
            <DropdownItem
              onClick={() => setIsEditTemplatesOpen(true)}
              disabled={templates.length == 0}
            >
              Edit user templates
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </ButtonGroup>
      <CreateExportTemplateModal isOpen={isCreateTemplateOpen} setIsOpen={handleModalState} />
      <EditTemplatesModal
        isOpen={isEditTemplatesOpen}
        setIsOpen={setIsEditTemplatesOpen}
        templates={templates}
      />
    </>
  );
};

export default ExportPlanButton;
