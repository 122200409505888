import { createSlice } from '@reduxjs/toolkit';
import { ExportTemplate } from '../../helpers/firebase_helper';
import { deleteTemplate, renameTemplate } from './thunk';

interface TemplatesState {
  templates: ExportTemplate[];
  loading: boolean;
  error: string | null;
}

const initialState: TemplatesState = {
  templates: [],
  loading: false,
  error: null,
};

const TemplatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setTemplates: (state, action) => {
      state.templates = action.payload.sort(
        (a: ExportTemplate, b: ExportTemplate) => b.updatedAt.toMillis() - a.updatedAt.toMillis(),
      );
      state.loading = false;
    },
    setLoadingTemplates: (state, action) => {
      state.loading = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(deleteTemplate.pending, (state) => {
        state.error = null;
      })
      .addCase(deleteTemplate.fulfilled, (state, action) => {
        state.templates = state.templates.filter((template) => template.id !== action.payload);
        state.loading = false;
      })
      .addCase(deleteTemplate.rejected, (state, action) => {
        state.error = action.error.message || 'Error deleting template';
      })
      .addCase(renameTemplate.pending, (state) => {
        state.error = null;
      })
      .addCase(renameTemplate.fulfilled, (state, action) => {
        state.templates = state.templates.map((template) =>
          template.id === action.payload.id
            ? { ...template, templateName: action.payload.templateName }
            : template,
        );
        state.loading = false;
      })
      .addCase(renameTemplate.rejected, (state, action) => {
        state.error = action.error.message || 'Error renaming template';
      });
  },
});

export const { setTemplates, setLoadingTemplates } = TemplatesSlice.actions;

export default TemplatesSlice.reducer;
