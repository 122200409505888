import { Plan } from '@helpers/firebase_helper';

export const getStatusDescription = (status: string) => {
  switch (status) {
    case 'uploading':
      return 'Uploading';
    case 'processingText':
      return 'Processing';
    case 'processingAI':
      return 'Processing';
    case 'processingLocationOCR':
    case 'processingLocationAI':
      return 'Initial Review Complete (Advanced Review in Process)';
    case 'inUserReview':
      return 'In Review';
    case 'archived':
      return 'Archived';
    case 'reviewed':
      return 'Reviewed';
    case 'failed':
      return 'Failed';
    default:
      return '';
  }
};

export const areAnswersAvailable = (status: string): boolean => {
  switch (status) {
    case 'uploading':
    case 'processingText':
    case 'processingAI':
    case 'failed':
      return false;
    case 'processingLocationOCR':
    case 'processingLocationAI':
    case 'inUserReview':
    case 'reviewed':
    case 'archived':
      return true;
    default:
      return false;
  }
};

export const areLocationsAvailable = (status: string): boolean => {
  switch (status) {
    case 'uploading':
    case 'processingText':
    case 'processingAI':
    case 'processingLocationOCR':
    case 'processingLocationAI':
    case 'failed':
      return false;
    case 'inUserReview':
    case 'reviewed':
    case 'archived':
      return true;
    default:
      return false;
  }
};

export const hasProcessingAnswersPlans = (plans: Plan[]): boolean => {
  return plans.some(
    (plan: Plan) =>
      plan.status === 'uploading' ||
      plan.status === 'processingText' ||
      plan.status === 'processingAI',
  );
};

export const canSelectPlanCheckbox = (status: string) => {
  return !['processingText', 'processingAI', 'uploading', 'failed'].includes(status);
};

export const canEditPlanName = (status: string) => {
  return !['processingText', 'processingAI', 'uploading', 'failed'].includes(status);
};

export const shouldShowSpinnerStatus = (status: string) => {
  return [
    'processingText',
    'processingAI',
    'processingLocationOCR',
    'processingLocationAI',
    'uploading',
  ].includes(status);
};

export const canDownloadFileOrViewAnswers = (status: string) => {
  return [
    'processingLocationOCR',
    'processingLocationAI',
    'archived',
    'reviewed',
    'inUserReview',
  ].includes(status);
};

export const getReviewPercentage = (plan: Plan): number => {
  if (plan.status === 'inUserReview' && plan.answers?.length) {
    const reviewedAnswerCount = plan.answers?.filter((answer) => answer.reviewed).length || 0;
    return Math.round((reviewedAnswerCount / plan.answers.length) * 100);
  }
  return 0;
};

export const mergePlanLists = (planList1: Plan[], planList2: Plan[]): Plan[] => {
  // copy first list.
  const mergedPlans: Plan[] = [...planList1];
  // get ids from the first list.
  const existingPlanIds = new Set(planList1.map((plan) => plan.id));

  for (const plan of planList2) {
    if (!existingPlanIds.has(plan.id)) {
      mergedPlans.push(plan);
      existingPlanIds.add(plan.id);
    }
  }

  mergedPlans.sort((a, b) => {
    const aCreatedAt = a.createdAt?.toDate() || new Date(0);
    const bCreatedAt = b.createdAt?.toDate() || new Date(0);
    return bCreatedAt.getTime() - aCreatedAt.getTime();
  });

  return mergedPlans;
};
