import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { renameTemplate } from 'slices/template/thunk';
interface EditTemplateDetailsModalProps {
  template: any;
  isOpen: boolean;
  setIsOpen: (arg0: boolean) => void;
  addNotification: (notification: string) => void;
}

const EditTemplateDetailsModal = ({
  template,
  isOpen,
  setIsOpen,
  addNotification,
}: EditTemplateDetailsModalProps) => {
  const dispatch = useDispatch<any>();
  const [templateName, setTemplateName] = useState<any>(template.templateName);

  const handleRenameTemplate = async () => {
    dispatch(renameTemplate({ id: template.id!, templateName })).then(() => {
      addNotification(`"${template.templateName}" was renamed to "${templateName}"`);
      setIsOpen(false);
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={() => setIsOpen}>
      <ModalHeader>Renaming Template "{template.templateName}"</ModalHeader>
      <ModalBody>
        <div>
          <label className=''>New Template Name</label>
          <input
            className='mx-3 px-1 rounded py-1 border-1'
            style={{ minWidth: '200px' }}
            type='text'
            value={templateName}
            onChange={(e) => {
              setTemplateName(e.target.value);
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className='px-3' color='secondary' onClick={handleRenameTemplate}>
          Save
        </Button>
        <Button
          className='px-3'
          color='gray-400'
          onClick={() => {
            setIsOpen(false);
          }}
        >
          Close without saving
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditTemplateDetailsModal;
