"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PDAIHelper = void 0;
const provisions_1 = require("./provisions");
class PDAIHelper {
    static parseLocationAnswerIntoObject(locationData) {
        const data = locationData
            .replace(/```json/g, '')
            .replace(/```/g, '')
            .replace(/"pl"/g, '"provisionLabel"')
            .replace(/"loc"/g, '"locations"')
            .replace(/"s"/g, '"section"')
            .replace(/"pn"/g, '"pageNumber"')
            .replace(/"t"/g, '"top"')
            .replace(/"b"/g, '"bottom"');
        console.log('parseLocationAnswerIntoObject (stringData): ' + data);
        const parsedLocationData = JSON.parse(data);
        console.log('parseLocationAnswerIntoObject (parsedData): ' + JSON.stringify(parsedLocationData));
        return parsedLocationData;
    }
    static addLocationToExistingAnswers(locationsData, existingAnswers) {
        const existingAnswersEnrichedWithLocations = locationsData.map((locationData) => {
            let newLocations = [];
            if (locationData.locations && locationData.locations.length > 0) {
                newLocations = [...locationData.locations];
            }
            return Object.assign(Object.assign({}, this.getProvisionAnswerFromLabel(locationData.provisionLabel, existingAnswers)), { locations: newLocations });
        });
        return existingAnswersEnrichedWithLocations;
    }
    static getJsonFromProvisionsData(provisionData) {
        const data = provisionData.replace('```json', '').replace('```', '');
        let provisions = [];
        provisions = JSON.parse(data);
        provisions = provisions.map((provision) => {
            return Object.assign(Object.assign({}, provision), { provisionName: this.getProvisionNameFromLabel(provision.provisionLabel) });
        });
        return provisions;
    }
    static getProvisionNameFromLabel(provisionLabel) {
        for (const provision of provisions_1.PDAIProvisions) {
            if (provision.provisionLabel == provisionLabel) {
                return provision.provisionName;
            }
        }
        throw new Error(`Error getting provision name from label ${provisionLabel}`);
    }
    static getProvisionAnswerFromLabel(provisionLabel, existingAnswers) {
        for (const provisionAnswer of existingAnswers) {
            if (provisionAnswer.provisionLabel == provisionLabel) {
                return provisionAnswer;
            }
        }
        throw new Error(`Error getting provision name for location from label ${provisionLabel}`);
    }
}
exports.PDAIHelper = PDAIHelper;
