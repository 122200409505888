import { Plan } from '@helpers/firebase_helper';
import { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
} from 'reactstrap';
import { getFirebaseBackend } from '../../firebase';

const OrganizationsAdmin = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const [organizationList, setOrganizationList] = useState<
    {
      organizationId: string;
      organizationName: string;
      numberOfAllowedDocuments: number;
    }[]
  >([]);
  const [selectedOrganization, setSelectedOrganization] = useState<{
    organizationId: string;
    organizationName: string;
    numberOfAllowedDocuments: number;
  } | null>(null);

  const [organizationUsers, setOrganizationUsers] = useState<
    {
      userId: string;
      email: string;
      numberOfAllowedDocuments: number;
    }[]
  >([]);

  const [organizationPlans, setOrganizationPlans] = useState<Partial<Plan>[]>([]);

  useEffect(() => {
    const fetchOrganizations = async () => {
      const firebaseHelper = getFirebaseBackend();
      setOrganizationList(await firebaseHelper.getOrganizationList());
    };

    fetchOrganizations();
  }, []);

  useEffect(() => {
    const fetchOrganizationDetails = async () => {
      if (selectedOrganization?.organizationId) {
        const firebaseHelper = getFirebaseBackend();
        setOrganizationUsers(
          await firebaseHelper.getOrganizationUsers(selectedOrganization.organizationId!),
        );
        setOrganizationPlans(
          await firebaseHelper.getOrganizationPlans(selectedOrganization.organizationId),
        );
      }
    };

    fetchOrganizationDetails();
  }, [selectedOrganization]);

  return (
    <Card className='planListRightPanel' style={{ minHeight: '70vh' }}>
      <Row className='p-3'>
        <Col className='col-12'>
          <Dropdown className='py-3' isOpen={dropdownOpen} toggle={toggleDropdown}>
            <div className='d-flex'>
              <DropdownToggle caret color='primary' className='category-filter'>
                <Row>
                  <Col className='col-10 text-start'>Select Organization</Col>
                  <Col className='col-2 text-end'>
                    <i className='mdi mdi-chevron-down px-1' />
                  </Col>
                </Row>
              </DropdownToggle>
              <DropdownMenu>
                {organizationList.map((organization) => (
                  <DropdownItem
                    key={organization.organizationId}
                    onClick={() => {
                      setSelectedOrganization(organization);
                    }}
                  >
                    {organization.organizationName}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </div>
          </Dropdown>

          {selectedOrganization && <h3>{selectedOrganization.organizationName} </h3>}

          {selectedOrganization && (
            <div className='d-flex'>
              <table className='m-3'>
                <tbody>
                  <tr>
                    <td style={{ width: '150px' }}>Organization id:</td>
                    <td className='px-3'>
                      <strong>{selectedOrganization.organizationId}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td># of Allowed Documents</td>
                    <td className='px-3'>
                      <strong>{selectedOrganization.numberOfAllowedDocuments}</strong>
                    </td>
                  </tr>
                  <tr></tr>
                </tbody>
              </table>
            </div>
          )}

          {selectedOrganization && organizationUsers?.length > 0 && (
            <div
              className='my-3'
              style={{ backgroundColor: '#ececec', maxHeight: '200px', overflowY: 'auto' }}
            >
              <Table>
                <thead>
                  <tr>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#dcdcdc' }}>
                      User Id
                    </th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#dcdcdc' }}>
                      User Email
                    </th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#dcdcdc' }}>
                      # of Allowed Documents (per user)
                    </th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#dcdcdc' }}>
                      Documents Processed
                    </th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#dcdcdc' }}>
                      Documents Failed
                    </th>
                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#dcdcdc' }}>
                      Documents Deleted
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {organizationUsers.map((user, index) => {
                    return (
                      <tr key={index}>
                        <td>{user.userId}</td>
                        <td>{user.email}</td>
                        <td>{user.numberOfAllowedDocuments}</td>
                        <td>
                          {
                            organizationPlans?.filter(
                              (plan) =>
                                plan.userId == user.userId &&
                                plan.status !== 'failed' &&
                                plan.status !== 'deleted',
                            ).length
                          }
                        </td>
                        <td>
                          {
                            organizationPlans?.filter(
                              (plan) => plan.userId == user.userId && plan.status === 'failed',
                            ).length
                          }
                        </td>
                        <td>
                          {
                            organizationPlans?.filter(
                              (plan) => plan.userId == user.userId && plan.status === 'deleted',
                            ).length
                          }
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
          <br></br>
        </Col>
      </Row>
    </Card>
  );
};

export default OrganizationsAdmin;
