import { Plan } from '@helpers/firebase_helper';
import { getStatusDescription } from 'pages/PlanList/planHelper';
import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
} from 'reactstrap';
import { getFirebaseBackend } from '../../firebase';

const UsersAdmin = () => {
  const [editingAllowedDocuments, setEditingAllowedDocuments] = useState(false);
  const [newAllowedDocuments, setNewAllowedDocuments] = useState('0');

  const [editingOrganization, setEditingOrganization] = useState(false);
  const [newOrganization, setNewOrganization] = useState<string>('none');
  const [organizationList, setOrganizationList] = useState<
    {
      organizationId: string;
      organizationName: string;
      numberOfAllowedDocuments: number;
    }[]
  >([]);

  const [newIsAdmin, setNewIsAdmin] = useState(false);

  const [usersActivity, setUsersActivity] = useState<
    {
      user: {
        uid: string;
        email: string;
        displayName: string;
        organizationId: string | null;
        provider: any;
        metadata: any;
        numberOfAllowedDocuments: string;
        isAdmin: boolean;
      };
      logins: {
        userId: string;
        email: string;
        timestamp: any;
        metadata: any;
      }[];
      plans: Plan[];
    }[]
  >([]);
  const [selectedUserActivity, setSelectedUserActivity] = useState<{
    user: {
      uid: string;
      email: string;
      displayName: string;
      organizationId: string | null;
      provider: any;
      metadata: any;
      numberOfAllowedDocuments: string;
      isAdmin: boolean;
    };
    logins: {
      userId: string;
      email: string;
      timestamp: any;
      metadata: any;
    }[];
    plans: Plan[];
  } | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleEditAllowedDocuments = (userActivity: any) => {
    setNewAllowedDocuments(userActivity.user.numberOfAllowedDocuments);
    setEditingAllowedDocuments(true);
  };

  const handleEditOrganization = (userActivity: any) => {
    setNewAllowedDocuments(userActivity.user.numberOfAllowedDocuments);
    setEditingOrganization(true);
  };

  const handleSaveAllowedDocuments = async (userActivity: any) => {
    try {
      const firebaseHelper = getFirebaseBackend();
      // Update the user's allowed documents in Firebase
      await firebaseHelper.updateUser(userActivity.user.uid, {
        settings: { numberOfAllowedDocuments: newAllowedDocuments },
      });
      setEditingAllowedDocuments(false);

      const newUsersActivityState = usersActivity.map((ua) =>
        ua.user.uid === userActivity.user.uid
          ? {
              ...ua,
              user: { ...ua.user, numberOfAllowedDocuments: newAllowedDocuments + '' },
            }
          : { ...ua },
      );

      setUsersActivity(newUsersActivityState);
      setNewAllowedDocuments(newAllowedDocuments);
    } catch (error) {
      console.error('Error updating allowed documents:', error);
    }
  };

  const handleSaveOrganization = async (userActivity: any) => {
    try {
      const firebaseHelper = getFirebaseBackend();
      await firebaseHelper.updateUser(userActivity.user.uid, {
        organizationId: newOrganization == 'none' ? null : newOrganization,
      });

      const newUsersActivityState = usersActivity.map((ua) =>
        ua.user.uid === userActivity.user.uid
          ? {
              ...ua,
              user: {
                ...ua.user,
                organizationId: newOrganization === 'none' ? null : newOrganization,
              },
            }
          : { ...ua },
      );

      setUsersActivity(newUsersActivityState);
      setEditingOrganization(false);
      handleClaimsChange();
    } catch (error) {
      console.error('Error updating allowed documents:', error);
    }
  };

  const handleSaveIsAdmin = async (userActivity: any, newIsAdmin: boolean) => {
    try {
      const firebaseHelper = getFirebaseBackend();
      await firebaseHelper.updateUser(userActivity.user.uid, {
        isAdmin: newIsAdmin,
      });

      const newUsersActivityState = usersActivity.map((ua) =>
        ua.user.uid === userActivity.user.uid
          ? {
              ...ua,
              user: {
                ...ua.user,
                isAdmin: newIsAdmin,
              },
            }
          : { ...ua },
      );

      setUsersActivity(newUsersActivityState);
      handleClaimsChange();
    } catch (error) {
      console.error('Error updating allowed documents:', error);
    }
  };

  useEffect(() => {
    const fetchUserActivity = async () => {
      const firebaseHelper = getFirebaseBackend();
      const allUsersActivity = await firebaseHelper.getUserActivity();
      setUsersActivity(allUsersActivity);
    };

    const fetchOrganizations = async () => {
      const firebaseHelper = getFirebaseBackend();
      setOrganizationList(await firebaseHelper.getOrganizationList());
    };

    fetchUserActivity();
    fetchOrganizations();
  }, []);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const handleClaimsChange = async () => {
    const firebaseHelper = getFirebaseBackend();
    await firebaseHelper.setUserClaims(selectedUserActivity!.user.uid);

    const newUsersActivityState = usersActivity.map((userActivity) =>
      userActivity.user.uid === userActivity.user.uid
        ? {
            ...userActivity,
            user: {
              ...userActivity.user,
              numberOfAllowedDocuments: newAllowedDocuments + '',
              claims: newIsAdmin ? { admin: true } : {},
            },
          }
        : { ...userActivity },
    );

    setUsersActivity(newUsersActivityState);
    setSelectedUserActivity({
      ...selectedUserActivity!,
      user: {
        ...selectedUserActivity!.user,
        isAdmin: newIsAdmin,
      },
    });
  };

  const handleExportEmailForMailing = async () => {
    let allEmails = '';
    usersActivity.map((userActivity) => {
      allEmails += `${userActivity.user.email}, `;
    });

    await navigator.clipboard.writeText(allEmails);

    alert('All emails were copied in the clipboard separated by commas. ');
  };

  return (
    <Card className='planListRightPanel' style={{ minHeight: '70vh' }}>
      <Row className='p-3'>
        <Col className='col-8'>
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle caret color='primary' className='users-dropdown-filter'>
              <Row>
                <Col className='col-10 text-start'>Select User</Col>
                <Col className='col-2 text-end'>
                  <i className='mdi mdi-chevron-down px-1' />
                </Col>
              </Row>
            </DropdownToggle>
            <DropdownMenu>
              {usersActivity.map((userActivity) => (
                <DropdownItem
                  key={userActivity.user.uid}
                  onClick={() => {
                    setSelectedUserActivity(userActivity);
                    setNewAllowedDocuments(userActivity.user.numberOfAllowedDocuments);
                    setNewOrganization(userActivity.user.organizationId || 'none');
                    setNewIsAdmin(!!userActivity?.user?.isAdmin);
                  }}
                >
                  {userActivity.user.email} - {userActivity.user.displayName}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </Col>
        <Col className='col-4'>
          {!selectedUserActivity && (
            <Button type='button' className='btn' onClick={() => handleExportEmailForMailing()}>
              Export Emails for mailing
            </Button>
          )}
        </Col>
      </Row>
      {selectedUserActivity && (
        <div className='d-flex'>
          <table className='m-3'>
            <tbody>
              <tr>
                <td style={{ width: '150px' }}>User name:</td>
                <td className='px-3'>
                  <strong>{' ' + selectedUserActivity?.user.displayName}</strong>
                </td>
              </tr>
              <tr>
                <td>Email:</td>
                <td className='px-3'>
                  <strong>{selectedUserActivity?.user.email}</strong>
                </td>
              </tr>
              <tr>
                <td style={{ width: '100px' }}>Allowed Documents:</td>
                <td className='px-3'>
                  <div className='d-flex gap-2'>
                    {editingAllowedDocuments ? (
                      <Input
                        className='w-50'
                        type='number'
                        value={newAllowedDocuments}
                        onChange={(e) => {
                          setNewAllowedDocuments(e.target.value);
                        }}
                      />
                    ) : (
                      <strong>{' ' + newAllowedDocuments}</strong>
                    )}
                    {editingAllowedDocuments ? (
                      <Button
                        type='button'
                        className={`btn ${'edit-provision-button-active'}`}
                        onClick={() => handleSaveAllowedDocuments(selectedUserActivity)}
                      >
                        <i className='mdi mdi-check'></i>
                      </Button>
                    ) : (
                      <Button
                        type='button'
                        className='btn edit-provision-button'
                        onClick={() => handleEditAllowedDocuments(selectedUserActivity)}
                      >
                        <i className='mdi mdi-pencil-box-outline'></i>
                      </Button>
                    )}
                  </div>
                </td>
                <td># of documents processed:</td>
                <td className='px-3'>
                  <strong>
                    {selectedUserActivity?.plans.filter((plan) => plan.status !== 'failed').length}
                  </strong>
                </td>
              </tr>
              <tr>
                <td>Admin:</td>
                <td className='px-3'>
                  <input
                    type='checkbox'
                    checked={newIsAdmin}
                    onChange={async (e) => {
                      setNewIsAdmin(e.target.checked);
                      handleSaveIsAdmin(selectedUserActivity, e.target.checked);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ width: '100px' }}>Organization:</td>
                <td className='px-3'>
                  <div className='d-flex gap-2'>
                    {editingOrganization ? (
                      <Input
                        type='select'
                        value={newOrganization}
                        onChange={(e) => {
                          setNewOrganization(e.target.value);
                        }}
                      >
                        <option value={'none'} key={'none'} id={'none'}>
                          None
                        </option>
                        {organizationList.map((org) => (
                          <option
                            id={org.organizationId}
                            value={org.organizationId}
                            key={org.organizationId}
                          >
                            {org.organizationName}
                          </option>
                        ))}
                      </Input>
                    ) : (
                      <strong>
                        {organizationList.filter(
                          (organization) => organization.organizationId == newOrganization!,
                        )[0]?.organizationName || 'None'}
                      </strong>
                    )}
                    {editingOrganization ? (
                      <Button
                        type='button'
                        className={`btn ${'edit-provision-button-active'}`}
                        onClick={() => handleSaveOrganization(selectedUserActivity)}
                      >
                        <i className='mdi mdi-check'></i>
                      </Button>
                    ) : (
                      <Button
                        type='button'
                        className='btn edit-provision-button'
                        onClick={() => handleEditOrganization(selectedUserActivity)}
                      >
                        <i className='mdi mdi-pencil-box-outline'></i>
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {/* User Accesses */}
      {selectedUserActivity && selectedUserActivity.logins?.length > 0 && (
        <div style={{ backgroundColor: '#ececec', maxHeight: '200px', overflowY: 'auto' }}>
          {' '}
          <Table style={{ position: 'relative' }}>
            <thead>
              <tr>
                <th style={{ position: 'sticky', top: 0, backgroundColor: '#dcdcdc' }}>
                  Login Date & Time
                </th>
                <th style={{ position: 'sticky', top: 0, backgroundColor: '#dcdcdc' }}>Provider</th>
              </tr>
            </thead>
            <tbody>
              {selectedUserActivity.logins.map((access, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {new Date(access.timestamp._seconds * 1000).toLocaleDateString('en') +
                        ' ' +
                        new Date(access.timestamp._seconds * 1000).toLocaleTimeString('en')}
                    </td>
                    <td>{access.metadata.additionalUserInfo.providerId}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
      <br></br>
      {/* Uploaded Files */}
      {selectedUserActivity && (
        <div style={{ backgroundColor: '#ececec', maxHeight: '350px', overflowY: 'auto' }}>
          <Table style={{ position: 'relative' }}>
            <thead>
              <tr>
                <th style={{ position: 'sticky', top: 0, backgroundColor: '#dcdcdc' }}>Document</th>
                <th style={{ position: 'sticky', top: 0, backgroundColor: '#dcdcdc' }}>Status</th>
                <th style={{ position: 'sticky', top: 0, backgroundColor: '#dcdcdc' }}>
                  Updated At
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedUserActivity.plans.map((plan, index) => {
                return (
                  <tr key={index}>
                    <td>{plan.documentName || plan.fileName}</td>
                    <td>{getStatusDescription(plan.status) + ` (${plan.status})`}</td>
                    <td>
                      {plan.updatedAt &&
                        new Date((plan.updatedAt as any)._seconds * 1000).toLocaleDateString('en') +
                          ' ' +
                          new Date((plan.updatedAt as any)._seconds * 1000).toLocaleTimeString(
                            'en',
                          )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </Card>
  );
};

export default UsersAdmin;
