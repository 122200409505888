import { Plan } from '@helpers/firebase_helper';
import Spinners from 'Components/Common/Spinner';
import { AppDispatch } from 'index';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';
import { fetchPlanById } from 'slices/thunk';
import Breadcrumbs from '../../Components/Common/Breadcrumb';
import { setModals } from './dispatcher';
import MarkPlanAsReviewedModal from './modals/markPlanAsReviewedModal';
import PlanDetailsBody from './sections/PlanDetailsBody';
import PlanDetailsError from './sections/PlanDetailsError';
import PlanDetailsHeader from './sections/PlanDetailsHeader';

const PlanDetails = () => {
  document.title = 'PlanPort - Plan Details';
  document.body.classList.add('vertical-collpsed');

  const { id } = useParams();

  const dispatch: AppDispatch = useDispatch();
  const { plan, showMarkPlanAsReviewedModal } = useSelector((state: any) => ({
    plan: state.PlanDetails.plan as Plan,
    showMarkPlanAsReviewedModal: state.PlanDetails.modals.markPlanAsReviewed as boolean,
  }));

  useEffect(() => {
    dispatch(fetchPlanById(id!));
  }, [id]);

  return (
    <div className='page-content'>
      <React.Fragment>
        <Container fluid>
          <Breadcrumbs title='My Plans' breadcrumbItem='Plan Review' />
          <Row>
            <Col xs={12}>
              <Card>
                {!plan || plan.id !== id ? (
                  <Spinners />
                ) : (
                  <>
                    <PlanDetailsHeader />
                    <PlanDetailsError />
                    <PlanDetailsBody />
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>

        <MarkPlanAsReviewedModal
          isOpen={showMarkPlanAsReviewedModal}
          setIsOpen={(arg: boolean) => setModals(dispatch, { markPlanAsReviewed: arg })}
          plan={plan}
        />
      </React.Fragment>
    </div>
  );
};

export default PlanDetails;
