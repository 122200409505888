import { createSlice } from '@reduxjs/toolkit';

import { Plan } from '@helpers/firebase_helper';
import { fetchPlansByIds, selectPlan, updatePlan } from './thunk';

type SerializablePlan = Omit<Plan, 'updatedAt' | 'createdAt'>;

interface PlanListState {
  plans: SerializablePlan[];
  plansForComparison: SerializablePlan[];
  planIds: string[];
  selectedPlanIds: string[];
  loading: boolean;
  error: string | null;
}

const initialState: PlanListState = {
  plans: [],
  plansForComparison: [],
  planIds: [],
  selectedPlanIds: [],
  loading: false,
  error: null,
};

const PlansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setPlans: (state, action) => {
      state.plans = action.payload;
      state.loading = false;
    },
    setLoadingPlans: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePlan.fulfilled, (state, action) => {
        if (action.payload.status === 'deleted') {
          state.plans = state.plans.filter((plan) => plan.id !== action.payload.id);
        } else {
          state.plans = [
            ...state.plans.map((plan) =>
              plan.id === action.payload.id ? { ...plan, ...action.payload } : plan,
            ),
          ];
        }
        state.loading = false;
      })
      .addCase(selectPlan.fulfilled, (state, action) => {
        if (state.selectedPlanIds.includes(action.payload)) {
          const index = state.selectedPlanIds.indexOf(action.payload);
          state.selectedPlanIds.splice(index, 1);
        } else {
          state.selectedPlanIds.push(action.payload);
        }
        state.loading = false;
      })

      .addCase(fetchPlansByIds.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPlansByIds.fulfilled, (state, action) => {
        state.plansForComparison = action.payload;
        state.loading = false;
      })
      .addCase(fetchPlansByIds.rejected, (state, action) => {
        state.error = action.error.message || 'Error fetching plans by IDs';
        state.loading = false;
      });
  },
});

export const { setPlans, setLoadingPlans } = PlansSlice.actions;

export default PlansSlice.reducer;
