import { CategoryList } from '@pdai/shared';

import { AppDispatch } from 'index';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Row } from 'reactstrap';
import { setLayout } from '../dispatcher';

interface PlanDetailsFilterProps {
  componentRef: React.RefObject<HTMLDivElement>;
  selectedCategories: string[];
  setSelectedCategories: (value: string[]) => void;
}

const PlanDetailsFilter: React.FC<PlanDetailsFilterProps> = ({
  componentRef,
  selectedCategories,
  setSelectedCategories,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const { isFullScreen, displayPDF } = useSelector((state: any) => ({
    isFullScreen: state.PlanDetails.layout.isFullScreen as boolean,
    displayPDF: state.PlanDetails.layout.displayPDF as boolean,
  }));

  const [isCategoryDropdownOpen, setCategoryDropdownOpen] = useState(false);

  const pdfDisplayToggle = () => {
    setLayout(dispatch, { displayPDF: !displayPDF, pdfLoaded: true });
  };

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      const element = componentRef.current;
      if (!element) {
        return;
      }
      document.body.removeAttribute('data-layout-mode');
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if ((element as any).webkitRequestFullscreen) {
        /* Safari */
        (element as any).webkitRequestFullscreen();
      } else if ((element as any).msRequestFullscreen) {
        /* IE11 */
        (element as any).msRequestFullscreen();
      }
    } else {
      if ((document as any).exitFullscreen) {
        (document as any).exitFullscreen();
      } else if ((document as any).webkitExitFullscreen) {
        /* Safari */
        (document as any).webkitExitFullscreen();
      } else if ((document as any).mozCancelFullScreen) {
        /* Firefox */
        (document as any).mozCancelFullScreen();
      } else if ((document as any).msExitFullscreen) {
        /* IE11 */
        (document as any).msExitFullscreen();
      }
    }
    setLayout(dispatch, { isFullScreen: !isFullScreen });
  };

  const handleCategoryClick = (category: string) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter((c) => c !== category));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  return (
    <Row dir='row' className='provisionTableOptions'>
      <Col className='col-8'>
        <div className='displayCategoriesFilter'>
          <Dropdown
            isOpen={isCategoryDropdownOpen}
            toggle={() => setCategoryDropdownOpen((prevState) => !prevState)}
          >
            <div className='d-flex'>
              <DropdownToggle caret color='primary' className='category-filter'>
                <Row>
                  <Col className='col-10 text-start'>Select Categories</Col>
                  <Col className='col-2 text-end'>
                    <i className='mdi mdi-chevron-down px-1' />
                  </Col>
                </Row>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    setSelectedCategories([]);
                  }}
                >
                  Clear Filter
                </DropdownItem>
                <div className='dropdown-divider'></div>
                {CategoryList.map((category) => (
                  <DropdownItem
                    key={category}
                    onClick={() => handleCategoryClick(category)}
                    toggle={false}
                    disabled={
                      selectedCategories.length >= 3 && !selectedCategories.includes(category)
                    }
                  >
                    <Input type='checkbox' checked={selectedCategories.includes(category)} />{' '}
                    {category}
                  </DropdownItem>
                ))}
              </DropdownMenu>
              <div className='mt-2 px-3'>{selectedCategories.join(', ')}</div>
            </div>
          </Dropdown>
        </div>
      </Col>
      <Col className='col-4'>
        <div className='displayPDF'>
          <span className='showFullScreenLabel'>
            {isFullScreen ? 'Exit full-screen' : 'Show full-screen'}
          </span>
          <i className='bx bx-fullscreen setFullScreen' onClick={toggleFullScreen} />
          <span className='displayPDFLabel'>Display PDF</span>
          <div className='form-check form-switch form-switch-lg'>
            <Input type='switch' checked={displayPDF} onClick={pdfDisplayToggle} />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default PlanDetailsFilter;
