import { ProvisionAnswer } from '@pdai/shared';
import PDFJSViewer from 'Components/Common/PDFJSViewer/PDFJSViewer';

import { AppDispatch } from 'index';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { Plan } from '../../../helpers/firebase_helper';
import PlanDetailsFilter from '../components/PlanDetailsFilter';
import PlanDetailsTable from '../components/PlanDetailsTable';
import { setError } from '../dispatcher';

const PlanDetailsBody: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { plan, displayPDF, pdfLoaded } = useSelector((state: any) => ({
    plan: state.PlanDetails.plan as Plan,
    displayPDF: state.PlanDetails.layout.displayPDF as boolean,
    pdfLoaded: state.PlanDetails.layout.pdfLoaded as boolean,
  }));

  const componentRef = useRef<HTMLDivElement>(null);

  const [pdfDoc, setPdfDoc] = useState<PDFDocumentProxy>();
  const [selectedProvision, setSelectedProvision] = useState<ProvisionAnswer | null>(null);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  return (
    <div ref={componentRef} className='provisionsTableFullScreen my-3'>
      <PlanDetailsFilter
        componentRef={componentRef}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
      />
      <Row>
        <Col className={`${displayPDF ? 'col-5' : 'col-12'} answers-container`}>
          <PlanDetailsTable
            plan={plan}
            displayPDF={displayPDF}
            selectedCategories={selectedCategories}
            setErrorMessage={(error: string) => setError(dispatch, error)}
            selectedProvision={selectedProvision}
            setSelectedProvision={setSelectedProvision}
          />
        </Col>
        <Col className={`${displayPDF ? 'col-7' : 'd-none'} pdf-container`}>
          {plan.downloadUrl && (
            <div className='viewerContainer' id='pdfViewerForAnnotation'>
              <PDFJSViewer
                pdfUrl={plan.downloadUrl}
                setPdfDoc={setPdfDoc}
                getPdfDoc={() => {
                  return pdfDoc!;
                }}
                refreshPDF={displayPDF || pdfLoaded}
              />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PlanDetailsBody;
