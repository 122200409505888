import { getDownloadURL, ref } from 'firebase/storage';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { deleteTemplate } from 'slices/template/thunk';
import { getFirebaseBackend } from '../../../../../firebase';
import { downloadFile } from '../../../../../helpers/file_download';
import { ExportTemplate } from '../../../../../helpers/firebase_helper';
import EditTemplateDetailsModal from './EditTemplateDetailsModal';
interface EditTemplatesModalProps {
  templates: any[];
  isOpen: boolean;
  setIsOpen: (arg0: boolean) => void;
}

const EditTemplatesModal = ({ isOpen, setIsOpen, templates }: EditTemplatesModalProps) => {
  const firebaseHelper = getFirebaseBackend();
  const dispatch = useDispatch<any>();

  const [notifications, setNotifications] = useState<any>([]);
  const addNotification = (notification: string) => {
    setNotifications([...notifications, notification]);
  };

  const downloadTemplate = async (template: ExportTemplate) => {
    const url = await getDownloadURL(ref(firebaseHelper.storage, template.fileUri));
    const res = await fetch(url);
    downloadFile(await res.blob(), template.templateName || 'export_template.xlsx');
  };

  const handleDeleteTemplate = async (template: ExportTemplate) => {
    dispatch(deleteTemplate({ id: template.id! })).then(() => {
      addNotification(`"${template.templateName}" was deleted`);
    });
  };

  const [isEditTemplateDetailsOpen, setIsEditTemplateDetailsOpen] = useState<any>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  const handleEditTemplateDetails = (template: ExportTemplate) => {
    setSelectedTemplate(template);
    setIsEditTemplateDetailsOpen(true);
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={() => setIsOpen}>
        <ModalHeader>Edit Export Templates</ModalHeader>
        <ModalBody>
          <div>
            <p>Here you can download, edit and delete your uploaded export templates.</p>

            <div className=''>
              <Table className='table align-middle table-nowrap table-hover mb-0'>
                <thead>
                  <tr>
                    <th scope='col'>Name</th>
                    <th scope='col'>Date modified</th>
                  </tr>
                </thead>
                <tbody>
                  {(templates || [])?.map((item: ExportTemplate, key: number) => (
                    <tr key={key}>
                      <td className='text-dark fw-medium'>{item.templateName}</td>
                      <td>{item.updatedAt.toDate().toLocaleString()}</td>
                      <td>
                        <UncontrolledDropdown>
                          <DropdownToggle tag='a' className='font-size-16 text-muted' role='button'>
                            <i className='mdi mdi-dots-horizontal'></i>
                          </DropdownToggle>

                          <DropdownMenu className='dropdown-menu-start'>
                            <DropdownItem onClick={() => downloadTemplate(item)}>
                              Download
                            </DropdownItem>
                            <DropdownItem
                              href='#'
                              onClick={handleEditTemplateDetails.bind(null, item)}
                            >
                              Edit
                            </DropdownItem>
                            <div className='dropdown-divider'></div>
                            <DropdownItem onClick={() => handleDeleteTemplate(item)}>
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            <div className='mt-3'>
              {notifications.map((notification: any, key: number) => (
                <div key={key}>
                  <div className='flex align-items-center bg-light text-dark border border-dark rounded p-2 mb-2 d-flex'>
                    <strong className='me-auto'>{notification}</strong>
                    <button
                      type='button'
                      className='btn-close'
                      onClick={() =>
                        setNotifications(notifications.filter((n: any) => n !== notification))
                      }
                    ></button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className='px-3'
            color='gray-400'
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
      {isEditTemplateDetailsOpen && (
        <EditTemplateDetailsModal
          isOpen={isEditTemplateDetailsOpen}
          setIsOpen={setIsEditTemplateDetailsOpen}
          template={selectedTemplate}
          addNotification={addNotification}
        />
      )}
    </>
  );
};

export default EditTemplatesModal;
