const Separator = (props) => {
  return (
    <div
      style={{
        position: 'absolute',
        height: '100%',
        transform: `rotate(${props.turns}turn)`,
      }}
    >
      <div style={props.style} />
    </div>
  );
};

const RadialSeparators = (props) => {
  const turns = 1 / props.count;
  return (
    <>
      {Array.from({ length: props.count }, (_, index) => {
        return <Separator key={`separator-${index}`} turns={index * turns} style={props.style} />;
      })}
    </>
  );
};

export default RadialSeparators;
