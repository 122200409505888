import { Plan } from '@helpers/firebase_helper';
import Spinner from 'Components/Common/Spinner';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Card, Col, Container, Nav, NavItem, Row } from 'reactstrap';
import { createSelector } from 'reselect';
import { selectPlan, subscribeToPlans, updatePlan } from 'slices/thunk';
import Breadcrumbs from '../../Components/Common/Breadcrumb';
import withRouter from '../../Components/Common/withRouter';
import { hasProcessingAnswersPlans } from './planHelper';
import PlanTable from './planTable';
import UploadFileDropZone from './uploadFileDropzone';

const PlanList = () => {
  document.title = 'PlanPort';

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const selectorPlans = createSelector(
    (state: any) => state.PlanList,
    (Plan) => ({
      plans: Plan.plans,
      loading: Plan.loading,
      error: Plan.error,
      selectedPlanIds: Plan.selectedPlanIds,
    }),
  );

  const { plans, loading, error, selectedPlanIds }: any = useSelector(selectorPlans);
  const [filteredPlans, setFilteredAgreements] = useState<any>([]);

  const [activeTab, setActiveTab] = useState<any>('1');

  const [showAnswersModal, setShowAnswersModal] = useState(false);
  const [selectedAgreementAnswers, setSelectedAgreementAnswers] = useState<any>(null);
  const [processingFileMessage, setProcessingFileMessage] = useState('');
  const [warningMessage, setWarningMessage] = useState('');
  const [showUploadDropzone, setShowUploadDropzone] = useState(false);
  const [showArchivedPlans, setShowArchivedPlans] = useState(false);

  const handleViewAnswers = (answers: any) => {
    setSelectedAgreementAnswers(answers);
    setShowAnswersModal(true);
  };

  useEffect(() => {
    const unsubscribe = dispatch(subscribeToPlans());
    return () => {
      if (unsubscribe && typeof unsubscribe === 'function') unsubscribe();
    };
  }, [dispatch]);

  useEffect(() => {
    setFilteredAgreements(plans);

    if (!hasProcessingAnswersPlans(plans)) {
      setProcessingFileMessage('');
    }
  }, [plans]);

  const handleSelect = (selectedItems: any) => {
    dispatch(selectPlan(selectedItems));
  };

  const handleArchivePlans = () => {
    for (const planId of selectedPlanIds) {
      dispatch(updatePlan({ id: planId, updatedData: { status: 'archived' } }));
      handleSelect(planId);
    }
  };

  const onCompleteUpload = async () => {
    if (showUploadDropzone) {
      setShowUploadDropzone(false);
    }

    setProcessingFileMessage(
      'Your document is processing... Initial results should be available in several minutes while detailed results continue to process.',
    );
  };

  // search
  const handleSearch = (searchQuery: any) => {
    const filteredAgreements = plans.filter((plan: Plan) =>
      (plan.documentName || plan.fileName).toLowerCase().includes(searchQuery.toLowerCase()),
    );
    setFilteredAgreements(filteredAgreements);
  };

  const [displayStatus, setDisplayStatus] = useState('all');
  const filterPlans = ({ status }: any) => {
    return (displayStatus === 'all' && status !== 'archived') || displayStatus === status;
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs breadcrumbItem='My Plans' />

          <Row>
            <Col xs={12}>
              <div className='d-flex'>
                <div className='d-flex flex-column '>
                  <div>
                    <Card className='email-leftbar'>
                      <div>
                        <Nav
                          tabs
                          className='nav-tabs-custom plans-status-list'
                          vertical
                          role='tablist'
                        >
                          <NavItem>
                            <a
                              href='#!'
                              className={activeTab === '1' ? 'active-tab' : 'regular-tab'}
                              onClick={() => {
                                setActiveTab('1');
                                setDisplayStatus('all');
                                setShowArchivedPlans(false);
                              }}
                            >
                              All
                              <span className='ml-1 float-end'>
                                {
                                  filteredPlans.filter((plan: Plan) => plan.status !== 'archived')
                                    .length
                                }
                              </span>
                            </a>
                          </NavItem>

                          <NavItem>
                            <a
                              href='#!'
                              className={activeTab === '2' ? 'active-tab' : 'regular-tab'}
                              onClick={() => {
                                setActiveTab('2');
                                setDisplayStatus('inUserReview');
                                setShowArchivedPlans(false);
                              }}
                            >
                              In Review
                              <span className='ml-1 float-end'>
                                {
                                  filteredPlans.filter(
                                    (plan: Plan) => plan.status === 'inUserReview',
                                  ).length
                                }
                              </span>
                            </a>
                          </NavItem>

                          <NavItem>
                            <a
                              href='#!'
                              className={activeTab === '3' ? 'active-tab' : 'regular-tab'}
                              onClick={() => {
                                setActiveTab('3');
                                setDisplayStatus('reviewed');
                                setShowArchivedPlans(false);
                              }}
                            >
                              Reviewed
                              <span className='ml-1 float-end'>
                                {
                                  filteredPlans.filter((plan: Plan) => plan.status === 'reviewed')
                                    .length
                                }
                              </span>
                            </a>
                          </NavItem>
                        </Nav>
                      </div>
                    </Card>
                  </div>
                  <div>
                    <Card className='email-leftbar'>
                      <div className='agreement-status-list mt-1 text-center'>
                        <Button
                          type='button'
                          color={showArchivedPlans ? 'warning' : 'gray-400'}
                          className='btn waves-effect waves-light px-4'
                          onClick={() => {
                            setShowArchivedPlans(!showArchivedPlans);
                            if (!showArchivedPlans) {
                              setActiveTab('4');
                              setDisplayStatus('archived');
                            } else {
                              setActiveTab('1');
                              setDisplayStatus('all');
                            }
                          }}
                        >
                          {showArchivedPlans ? 'Hide archived plans' : 'Show archived plans'}
                        </Button>
                      </div>
                    </Card>
                  </div>
                </div>

                <div className='planListRightPanel'>
                  {error && (
                    <div className='align-items-center text-center p-4'>
                      <Alert color='danger' role='alert'>
                        Error: {error}
                      </Alert>
                    </div>
                  )}

                  {loading && <Spinner />}

                  {/* Add new button and DropZone above the list. */}
                  {!loading && !!plans.length && (
                    <>
                      <div className='pb-3 text-end'>
                        <Button
                          type='button'
                          color={showUploadDropzone ? 'warning' : 'secondary'}
                          className='btn waves-effect waves-light px-5'
                          onClick={() => setShowUploadDropzone(!showUploadDropzone)}
                        >
                          {showUploadDropzone ? 'Cancel New' : 'Add New'}
                        </Button>
                      </div>

                      {showUploadDropzone && (
                        <UploadFileDropZone onCompleteUpload={onCompleteUpload} uploadType='plan' />
                      )}
                    </>
                  )}
                  <Card>
                    {!loading && plans.length > 0 && (
                      <>
                        <div className='p-3'>
                          <Row className='justify-content-between'>
                            <Col lg={4}>
                              <div className='search-box mb-2'>
                                <div className='position-relative'>
                                  <input
                                    type='text'
                                    className='form-control bg-light border-light rounded2'
                                    placeholder='Find a document...'
                                    onChange={(e: any) => handleSearch(e.target.value)}
                                  />
                                  <i className='bx bx-search-alt search-icon'></i>
                                </div>
                              </div>
                            </Col>
                            <Col lg={8} className='text-end'>
                              {selectedPlanIds.length > 1 && (
                                <Button
                                  type='button'
                                  color='secondary'
                                  className='btn px-5 plansOptionsButtons'
                                  onClick={() => {
                                    setWarningMessage('');
                                    if (selectedPlanIds.length > 3) {
                                      setWarningMessage('You can compare up to 3 plans at a time.');
                                      return;
                                    }
                                    navigate(`/planComparison/${selectedPlanIds.join(',')}`);
                                  }}
                                >
                                  Compare Selected
                                </Button>
                              )}
                              {selectedPlanIds.length > 0 && (
                                <Button
                                  type='button'
                                  color='secondary'
                                  className='btn px-5 plansOptionsButtons'
                                  onClick={() => {
                                    handleArchivePlans();
                                  }}
                                >
                                  Archive Selected Plans
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </div>
                        {processingFileMessage && (
                          <div className='px-3'>
                            <Alert
                              isOpen={!!processingFileMessage}
                              toggle={() => {
                                setProcessingFileMessage('');
                              }}
                            >
                              {processingFileMessage}
                            </Alert>
                          </div>
                        )}
                        {warningMessage && (
                          <div className='px-3'>
                            <Alert
                              color='warning'
                              isOpen={!!warningMessage}
                              toggle={() => {
                                setWarningMessage('');
                              }}
                            >
                              {warningMessage}
                            </Alert>
                          </div>
                        )}
                        <PlanTable
                          plans={filteredPlans.filter(({ status }: any) => filterPlans({ status }))}
                          selectedPlanIds={selectedPlanIds}
                          handleSelect={handleSelect}
                          handleViewAnswers={handleViewAnswers}
                        />
                      </>
                    )}

                    {!loading && plans.length === 0 && (
                      <div className='align-items-center text-center p-4'>
                        <h3 className='fw-bold'>Welcome to PlanPort! Let's get started.</h3>
                        <h5 className='p-4'>
                          Upload your first document to begin PlanPort Analysis.
                        </h5>

                        <br></br>

                        <UploadFileDropZone onCompleteUpload={onCompleteUpload} uploadType='plan' />
                      </div>
                    )}
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PlanList);
