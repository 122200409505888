import { createSlice } from '@reduxjs/toolkit';

import { Plan } from '@helpers/firebase_helper';
import { fetchPlanById, updatePlan, updatePlanProvision } from './thunk';

type SerializablePlan = Omit<Plan, 'updatedAt' | 'createdAt'>;

interface PlanDetailsState {
  plan: SerializablePlan | null;
  loading: boolean;
  modals: {
    markPlanAsReviewed: boolean;
    summary: boolean;
  };
  layout: {
    isFullScreen: boolean;
    displayPDF: boolean;
    pdfLoaded: boolean;
    summaryLoading: boolean;
  };
  error: string;
}

const initialState: PlanDetailsState = {
  plan: null,
  modals: {
    summary: false,
    markPlanAsReviewed: false,
  },
  layout: {
    isFullScreen: false,
    displayPDF: false,
    pdfLoaded: false,
    summaryLoading: false,
  },
  loading: false,
  error: '',
};

const PlanDetailsSlice = createSlice({
  name: 'planDetails',
  initialState,
  reducers: {
    setPlan: (state, action) => {
      state.plan = action.payload;
      state.loading = false;
    },
    setLoadingPlan: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setModals: (state, action) => {
      state.modals = { ...state.modals, ...action.payload };
    },
    setLayout: (state, action) => {
      state.layout = { ...state.layout, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePlan.pending, (state) => {
        state.error = '';
      })
      .addCase(updatePlan.fulfilled, (state, action) => {
        if (action.payload.status === 'deleted') {
          if (state.plan?.id === action.payload.id) {
            state = initialState;
          }
        } else {
          state.plan = { ...state.plan!, ...action.payload };
          state.loading = false;
        }
      })
      .addCase(updatePlan.rejected, (state, action) => {
        state.error = action.error.message || 'Error updating plan';
      })
      .addCase(fetchPlanById.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchPlanById.fulfilled, (state, action) => {
        state.plan = action.payload;
        state.loading = false;
      })
      .addCase(fetchPlanById.rejected, (state, action) => {
        state.error = action.error.message || 'Error fetching plan by ID';
        state.loading = false;
      })
      .addCase(updatePlanProvision.pending, (state) => {
        state.error = '';
      })
      .addCase(updatePlanProvision.fulfilled, (state, action) => {
        state.plan = {
          ...state.plan!,
          answers: [
            ...state.plan!.answers!.map((oldProvision: any) => {
              const foundProvision = action.payload.provisions.find(
                (newProvision) => oldProvision.provisionName === newProvision.provisionName,
              );
              return foundProvision || oldProvision;
            }),
          ],
        };
        state.modals.markPlanAsReviewed =
          (state.plan.status === 'inUserReview' &&
            state.plan.answers?.every((answer) => answer.reviewed)) ||
          false;
      })
      .addCase(updatePlanProvision.rejected, (state, action) => {
        state.error = action.error.message || 'Error saving provision review.';
      });
  },
});

export const { setPlan, setLoadingPlan } = PlanDetailsSlice.actions;

export default PlanDetailsSlice.reducer;
