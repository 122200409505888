import { PDAIProvisions } from '@pdai/shared/dist/provisions';
import * as Excel from 'exceljs';
import { downloadXLSX } from './file_download';
import { Plan } from './firebase_helper';
import { getPlanName } from './plan_helper';
// TODO: error treatment when no answers are available
// TODO: error treatment when no replacement was made

export const generateExcelProvisions = async (plan: Plan) => {
  const workbook = new Excel.Workbook();
  workbook.creator = 'PlanPort - PlanDataAI';
  workbook.lastModifiedBy = 'PlanPort - PlanDataAI';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 1,
      visibility: 'visible',
    },
  ];

  const sheet = workbook.addWorksheet(`${getPlanName(plan)}`);

  // Group answers by category
  const groupedAnswers = plan.answers!.reduce((acc, answer) => {
    const category = answer.category;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push({
      Provisions: answer.provisionLabel,
      Answer: answer.modelAnswer,
    });
    return acc;
  }, {} as { [key: string]: any[] });

  const categoryRows: number[] = [];
  const provisionRows: number[] = [];

  // Add category headers and answers to the data array with the new format
  for (const category in groupedAnswers) {
    sheet.addRow([category]);
    categoryRows.push(sheet.rowCount);

    groupedAnswers[category].forEach((answer) => {
      sheet.addRow([answer.Provisions, answer.Answer]);
      provisionRows.push(sheet.rowCount);
    });
  }

  const getLineCount = (text: string, maxCharsPerLine: number = 60): number => {
    if (!text) {
      return 1;
    }

    const lines = text.split('\n');
    let lineCount = lines.length;

    for (const line of lines) {
      if (line.length > maxCharsPerLine) {
        lineCount += Math.floor(line.length / maxCharsPerLine);
      }
    }

    return lineCount;
  };

  provisionRows.forEach((rowNumber) => {
    const provisionRow = sheet.getRow(rowNumber);
    provisionRow.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
    provisionRow.height = getLineCount(provisionRow.getCell(2).value!.toString(), 102) * 18;

    provisionRow.getCell(1).border = { bottom: { style: 'hair' } };
    provisionRow.getCell(2).border = { bottom: { style: 'hair' } };
  });

  categoryRows.forEach((rowNumber) => {
    const categoryRow = sheet.getRow(rowNumber);
    categoryRow.font = { bold: true };
    categoryRow.height = 30;
    categoryRow.font.color = { argb: 'ffffffff' };

    categoryRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0b628d' },
    };
    categoryRow.getCell(2).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0b628d' },
    };
    categoryRow.getCell(1).border = { bottom: { style: 'thin' } };
    categoryRow.getCell(2).border = { bottom: { style: 'thin' } };

    sheet.mergeCells(rowNumber, 1, rowNumber, 2);
  });

  sheet.columns[0].width = 50;
  sheet.columns[1].width = 90;

  // Generate the Excel file as a blob
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  // Create a temporary URL for the blob
  return window.URL.createObjectURL(blob);
};

export const downloadProvisionsTemplate = async () => {
  const emptyPlan = {
    answers: PDAIProvisions.map((provision) => ({
      category: provision.category,
      provisionName: provision.provisionName,
      provisionLabel: provision.provisionLabel,
      modelAnswer: `\${${provision.provisionName}}`,
      locations: [],
    })),
    fileName: 'plandata_export_template.xlsx',
    userId: '',
    status: '',
  };

  const template = await fetch(await generateExcelProvisions(emptyPlan));

  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(await template.arrayBuffer());

  await downloadXLSX(workbook, `plandata_export_template.xlsx`);
};

export const downloadProvisionsExport = async (plan: Plan, templateURL?: string) => {
  const template = await fetch(templateURL || (await generateExcelProvisions(plan)));

  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(await template.arrayBuffer());

  const answerMap = (plan.answers || [])?.reduce((acc, answer) => {
    acc.set(answer.provisionName, answer.modelAnswer);
    return acc;
  }, new Map<string, string>());

  workbook.worksheets.forEach((sheet) => {
    sheet?.eachRow((row, rowNumber) => {
      row.eachCell((cell, colNumber) => {
        if (typeof cell.value === 'string' && cell.value.includes('${')) {
          const key = cell.value.match(/\${(.*?)}/)?.[1];
          if (key && answerMap.has(key)) {
            cell.value = cell.value.replace(`\${${key}}`, answerMap.get(key)!);
            // console.log(`(${rowNumber},${colNumber}): "${key}" ---> "${cell.value}"`);
          }
        }
      });
    });
  });

  await downloadXLSX(workbook, `${getPlanName(plan)}_new_export.xlsx`);
};

export const validateUploadedFile = async (file: any) => {
  console.log('Validating file:', file);

  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(await file.arrayBuffer());

  let hasKey = false;
  workbook.worksheets.forEach((sheet) => {
    sheet?.eachRow((row) => {
      row.eachCell((cell) => {
        if (typeof cell.value === 'string' && cell.value.includes('${')) {
          const key = cell.value.match(/\${(.*?)}/)?.[1];
          if (key && PDAIProvisions.map((p) => p.provisionName).includes(key)) {
            hasKey = true;
          }
        }
      });
    });
  });

  if (!hasKey) {
    throw new Error('No provision placeholders found in the uploaded file.');
  }
};
