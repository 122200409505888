import * as Excel from 'exceljs';

export const downloadFile = async (blob: Blob, filename: string) => {
  // Create a temporary URL for the blob
  const url = window.URL.createObjectURL(blob);

  // Create a hidden link and trigger a click to download the file
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();

  // Clean up the URL object
  window.URL.revokeObjectURL(url);
};

export const downloadXLSX = async (workbook: Excel.Workbook, filename: string) => {
  // Generate the Excel file as a blob
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  await downloadFile(blob, filename);
};
