import { Plan } from '@helpers/firebase_helper';
import { AppDispatch } from 'index';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { updatePlan, updatePlanProvision } from 'slices/thunk';

interface MarkPlanAsReviewedModalProps {
  plan: Plan;
  isOpen: boolean;
  setIsOpen: (arg0: boolean) => void;
}

const MarkPlanAsReviewedModal: React.FC<MarkPlanAsReviewedModalProps> = ({
  plan,
  isOpen,
  setIsOpen,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const pendingProvisions = plan?.answers?.filter((answer) => !answer.reviewed) || [];
  const hasPendingProvisions = pendingProvisions.length > 0;

  const handleUpdatePlan = () => {
    if (hasPendingProvisions) {
      dispatch(
        updatePlanProvision({
          provisions: pendingProvisions.map((provision) => {
            return {
              ...provision,
              reviewed: true,
            };
          }),
          planId: plan.id!,
        }),
      );
    }
    dispatch(updatePlan({ id: plan.id!, updatedData: { status: 'reviewed' } }));
    setIsOpen(false);
  };

  const headerText = hasPendingProvisions
    ? `Pending Provisions Found (${pendingProvisions.length})`
    : 'Provisions Reviewed';
  const bodyText = hasPendingProvisions ? (
    <span>
      <p>
        There are provisions that have not yet been reviewed in this Plan. <br />
        Proceeding will mark them all as "Reviewed". <br />
      </p>
      Are you sure you want to proceed?
    </span>
  ) : (
    <span>
      All provisions associated with this plan have been reviewed. <br />
      Would you like to update the plan's status?
    </span>
  );

  return (
    <>
      <Modal size='lg' isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} className='modal-answers'>
        <ModalHeader toggle={() => setIsOpen(!isOpen)}>
          <strong>{headerText}</strong>
        </ModalHeader>
        <ModalBody>
          <div className='px-4 d-flex'>{bodyText}</div>
        </ModalBody>
        <ModalFooter className='px-4'>
          <Button className='px-4' color='primary' onClick={handleUpdatePlan}>
            Mark Plan as Reviewed
          </Button>
          <Button className='px-3' color='gray-400' onClick={() => setIsOpen(!isOpen)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default MarkPlanAsReviewedModal;
