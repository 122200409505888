import React, { useState } from 'react';
import { Card, Col, Container, Nav, NavItem, Row } from 'reactstrap';
import Breadcrumbs from '../../Components/Common/Breadcrumb';
import withRouter from '../../Components/Common/withRouter';
import OrganizationsAdmin from './OrganizationsAdmin';
import UsersAdmin from './UsersAdmin';

const AdminUserActivity = () => {
  document.title = 'Admin - User Activity';

  const [isShowingUsers, setIsShowingUsers] = useState(true);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs breadcrumbItem='Admin' />
          <Row>
            <Col xs={12}>
              <div className='d-flex'>
                <div className='d-flex flex-column '>
                  <div>
                    <Card className='email-leftbar'>
                      <div>
                        <Nav
                          tabs
                          className='nav-tabs-custom plans-status-list'
                          vertical
                          role='tablist'
                        >
                          <NavItem>
                            <a
                              href='#!'
                              className={isShowingUsers ? 'active-tab' : 'regular-tab'}
                              onClick={() => {
                                setIsShowingUsers(true);
                              }}
                            >
                              Users
                            </a>
                          </NavItem>

                          <NavItem>
                            <a
                              href='#!'
                              className={isShowingUsers ? 'regular-tab' : 'active-tab'}
                              onClick={() => {
                                setIsShowingUsers(false);
                              }}
                            >
                              Organizations
                            </a>
                          </NavItem>
                        </Nav>
                      </div>
                    </Card>
                  </div>
                </div>
                {isShowingUsers ? <UsersAdmin /> : <OrganizationsAdmin />}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AdminUserActivity);
