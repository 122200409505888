import { ExportTemplate } from '@helpers/firebase_helper';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { getFirebaseBackend } from '../../firebase';
import { setTemplates } from './reducer';

// Fetch logged user's templates
export const fetchUserTemplates = createAsyncThunk(
  'templates/fetchUserTemplates',
  async (_, { dispatch }) => {
    try {
      const firebaseHelper = getFirebaseBackend();
      const userId = firebaseHelper.getCurrentUser();
      const querySnapshot = await getDocs(
        query(collection(firebaseHelper.db, 'exportTemplates'), where('userId', '==', userId.uid)),
      );

      const templates = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as ExportTemplate[];

      dispatch(setTemplates(templates));
    } catch (error) {
      console.error('Error fetching user export templates:', error);
      throw error;
    }
  },
);

// Delete specific template
export const deleteTemplate = createAsyncThunk(
  'templates/delete',
  async ({ id }: { id: string }): Promise<string> => {
    await getFirebaseBackend().deleteTemplate(id);
    return id;
  },
);

// Rename specific template
export const renameTemplate = createAsyncThunk(
  'templates/rename',
  async ({
    id,
    templateName,
  }: {
    id: string;
    templateName: string;
  }): Promise<Partial<ExportTemplate>> => {
    await getFirebaseBackend().renameTemplate(id, templateName);
    return { id, templateName };
  },
);
