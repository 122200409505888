import RadialSeparators from 'Components/RadialSeparators';
import React from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ProgressCircle = ({ percentage }: any) => {
  return (
    <React.Fragment>
      <div style={{ width: 35, height: 35 }}>
        <CircularProgressbarWithChildren
          value={percentage}
          text={`${percentage}%`}
          counterClockwise={true}
          strokeWidth={15}
          background={true}
          styles={{
            text: { fontSize: '1.7rem', fill: 'black', fontWeight: '500' },
            path: {
              strokeLinecap: 'butt',
              stroke: '#014385',
              transform: 'rotate(0.1turn)',
              transformOrigin: 'center center',
            },
            background: { fill: '#F4F4F4' },
            trail: {
              stroke: 'transparent',
            },
          }}
        >
          <RadialSeparators
            count={24}
            style={{
              background: '#F4F4F4',
              width: '2px',
              height: `${15}%`,
              transform: 'scale(1.1)',
            }}
          />
        </CircularProgressbarWithChildren>
      </div>
    </React.Fragment>
  );
};

export default ProgressCircle;
